import { Avatar, Button, Card, Col, Image, MarkdownField, NumberField, Row, Show, Table, Tag, TagField, TextField, Typography, getDefaultSortOrder, useModalForm, useTable } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { AgeFromDateString } from "age-calculator";
import { genreName } from "helpers/artwork";
import { countryName, sellerTypeName } from "helpers/seller";
import { avatarUrl, phoneNumber } from "helpers/user";
import { IAuction, IUser } from "interfaces";
import routerProvider from "@pankod/refine-react-router-v6";
import { EditPasswordModal } from "pages/users/components/edit";
// import { ShowOrders } from "pages/users/components/show-orders";
import ReactTimeAgo from "react-time-ago";
import { orderStatusColor, orderStatusName, shippingStatusColor, shippingStatusName } from "helpers/order";

const { Title, Text } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { Link } = routerProvider;
  const { useLocation } = routerProvider;
  const { pathname } = useLocation();
  const ids = pathname.split('/');
  const userId = ids.at(-1);

  const { queryResult } = useShow<IUser>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const age = record?.dob ? new AgeFromDateString(record?.dob).age : null;

  const { tableProps, sorter } = useTable<IAuction>({
    resource: `users/${userId}/won_auctions`,
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
  });

  const { formProps, modalProps, show: showPasswordModal } = useModalForm({
    action: "edit",
    resource: `users/${userId}/password/admin`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    },
  });

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <EditPasswordModal modalProps={modalProps} formProps={formProps} />
      <Table
        {...tableProps}
        dataSource={(tableProps?.dataSource as any)?.data}
        rowKey="id"
      >
        <Table.Column<IAuction>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="image"
          title="낙찰받은 작품"
          render={(_, record) => (
            <Row style={{ gap: `10px` }}>
              <Link to={`/auctions/show/${record?.id}`}>
                <Avatar
                  size={40}
                  shape="square"
                  src={record?.images![0] ?? 'https://cdn.fleaauction.world/images/news.png'}
                  alt={record?.title}
                />
              </Link>
              <div>
                <div><TextField value={record?.title} /></div>
                <div><TextField value={record?.artistName} /></div>
              </div>
            </Row>
          )}
        />
        <Table.Column<IAuction>
          dataIndex=""
          title="낙찰일시"
          render={(_, record) => (
            <div>
              {
                record?.closingTime ? <ReactTimeAgo date={Date.parse(record?.closingTime)} locale="ko" /> : <Tag>기록없음</Tag>
              }
            </div>
          )}
        />
        <Table.Column<IAuction>
          dataIndex=""
          title="낙찰가"
          render={(_, record) => (
            <div><TextField value={`${(record?.order?.price ?? 0).toLocaleString()}원`} /></div>
          )}
        />
        <Table.Column<IAuction>
          dataIndex="order.orderStatus"
          title="결제상태"
          render={(_, record) => (
            <Link to={`/orders/show/${record?.order?.id}`}>
              <TagField
                color={orderStatusColor(record?.order?.orderStatus ?? '')}
                value={orderStatusName(record?.order?.orderStatus ?? '')}
              />
            </Link>
          )}
        />
        <Table.Column<IAuction>
          dataIndex="order.orderStatus"
          title="배송상태"
          render={(_, record) => (
            <div>
              <TagField
                color={shippingStatusColor(record?.order?.shippingStatus ?? '')}
                value={shippingStatusName(record?.order?.shippingStatus ?? '')}
              />
            </div>
          )}
        />
      </Table>
      <Card
        type="inner"
        title="사용자 기본정보"
        style={{marginBottom: 24}}
        extra={
          <Button
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => {
              // showPasswordModal()
            }}
          >사용자 비밀번호 변경</Button>

        }
      >
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            {
              record &&
              <Image
                width={64}
                src={avatarUrl(record?.avatar)}
                alt={record?.realname}
              />
            }
          </Col>
          <Col span={20}>
            <p>{record?.deletedAt ? <Tag color='red'>탈퇴회원</Tag> : <></>}</p>
            <p>{record?.isBanned ? <Tag color='red'>정지회원</Tag> : <></>}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Text delete={record?.deletedAt !== null}>{record?.username ?? 'n/a'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.isActive ? { color: "green"} : {}}>{record?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화번호</Title>
            {
              record &&
              <Text>{phoneNumber(record?.phone)}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {record?.realname ? <Text>{record?.realname}</Text> : <Text>n/a</Text>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>성별</Title>
            <Text>{record?.gender ? (record?.gender === 'M' ? <Text>남자</Text> : <Text>여자</Text>)  : 'n/a'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            <Text>{age ? `만${age}세` : 'n/a'}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>사용 정지여부</Title>
            {record?.isBanned ? <Tag color='red'>정지</Tag> : <Tag color='green'>정상</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일확인</Title>
            {record?.isActive ? <Tag color='green'>확인</Tag> : <Tag color='red'>미확인</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>프로필공개</Title>
            {record?.isAnonymous ? <Tag color='red'>미공개</Tag> : <Tag color='green'>공개</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>낙찰내역공개</Title>
            {record?.isPrivate ? <Tag color='red'>미공개</Tag> : <Tag color='green'>공개</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>푸시토큰</Title>
            {record?.pushToken ? <Text ellipsis>{record?.pushToken}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>토큰해쉬 | 탈퇴이유</Title>
            {record?.refreshTokenHash ? <Text ellipsis>{record?.refreshTokenHash}</Text> : <Tag>기록없음</Tag>}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>권한</Title>
            {record?.role !== 'USER' ? <Tag color='indigo'>{record?.role}</Tag> : <Tag>{record?.role}</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>언어</Title>
            <Text>{record?.locale === 'ko' ? '한국어' : '영어'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>가입일</Title>
            {record?.createdAt ? <ReactTimeAgo date={Date.parse(record?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최근사용일</Title>
            {record?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>탈퇴일</Title>
            {record?.deletedAt ? <ReactTimeAgo date={Date.parse(record?.deletedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
        </Row>
      </Card>
      <Card type="inner" title="사용자 프로필정보" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>푸시알림</Title>
            <Text>{record?.profile?.notifyPush ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>카카오알림</Title>
            <Text>{record?.profile?.notifyKakao ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일알림</Title>
            <Text>{record?.profile?.notifyEmail ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이벤트알림</Title>
            <Text>{record?.profile?.notifyEvent ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>조회수</Title>
            <Text>{record?.profile?.viewCount.toLocaleString()}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>결제횟수</Title>
            <Text>{record?.profile?.payCount.toLocaleString()}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>소개글</Title>
            {
              record?.profile?.bio
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.profile?.bio} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
      </Card>
      {/* <ShowOrders /> */}
      <Card
        type="inner"
        title="셀러정보"
      >
        {!record?.artist && (
          <Row gutter={[16, 16]}>
            <Col span={24}><Text>셀러정보가 없는 일반사용자입니다.</Text></Col>
          </Row>)}
        {record?.artist && (
          <Row gutter={[16, 16]} style={{marginBottom: 16}}>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>셀러타입</Title>
              <Text style={{marginRight: 8}}>{sellerTypeName(record?.artist?.sellerType)}</Text>
              <Tag color={record?.artist?.isApproved ? 'green' : 'red'}>{record?.artist?.isApproved ? '인증' : '미인증'}</Tag>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>작가명</Title>
              <Text>{record?.artist?.name ?? ''}</Text>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>장르</Title>
              <Text>{genreName(record?.artist?.genre)}</Text>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>국적</Title>
              <Text>{countryName(record?.artist?.nationality)}</Text>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>생성일</Title>
              {
                record?.artist?.createdAt ? <ReactTimeAgo date={Date.parse(record?.artist?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>
              }
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>최근수정일</Title>
              {
                record?.artist?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.artist?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>
              }
            </Col>
          </Row>
        )}
      </Card>
    </Show>
  );
};
