import {
  CrudFilter,
  CrudFilters,
  CrudOperators,
  CrudSorting,
} from '@pankod/refine-core'
import dataProvider from '@pankod/refine-simple-rest'
import { AxiosInstance } from 'axios'
import { stringify } from 'query-string'

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {}
  if (filters) {
    console.log(filters);
    filters.map((filter: CrudFilter) => {
      if (filter.operator !== 'or') {
        const { field, operator, value } = filter;

        console.log(field, value);

        if (field === 'q') {
          queryFilters[field] = value
          return null;
        }
        if (field === 'phone' || field === 'email') {
          queryFilters[`filter.${field}`] = `$sw:${value}`;
          delete queryFilters[field];
        }
        if (field === 'username' || field === 'realname') {
          queryFilters[`filter.${field}`] = `$ilike:${value}`
          delete queryFilters[field]
        }
        if (field === 'name' || field === 'title') { // artist, artwork, auctions
          queryFilters[`filter.${field}`] = `$ilike:${value}`
          delete queryFilters[field]
        }
        if (field === 'category') {
          queryFilters[`filter.${field}`] = `$eq:${value}`
          delete queryFilters[field]
        }
        // const mappedOperator = mapOperator(operator)
        // queryFilters[`${field}${mappedOperator}`] = value
      }
    })
  }
  return queryFilters
}

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = []
    sort.map((item) => {
      _sort.push(`${item.field}:${item.order.toUpperCase()}`)
    })
    return {
      _sort,
    }
  }
  return
}

const getUrl = (url: string, resource: string | null) => {
  switch (resource) {
    case 'users':
    case 'artists':
    case 'articles':
    case 'banners':
    case 'events':
    case 'news':
    case 'packs':
    case 'payments':
      return `${url}/${resource}/admin`
    case 'users.search':
      return `${url}/users`
    case 'artists.search':
      return `${url}/artists`
  }
  console.log('api => ', `${url}/${resource}`)
  return `${url}/${resource}`
}

export const apiDataProvider = (apiUrl: string, httpClient: AxiosInstance) => ({
  ...dataProvider(apiUrl, httpClient),

  createMany: async ({ resource = null, variables = {} }) => {
    const url = `${apiUrl}/${resource}/many`
    const { data } = await httpClient.put(url, variables);

    console.log(`data => `, data);

    return {
      data,
    }
  },

  //?-------------------------------------------------------------------------//
  //? update
  //?-------------------------------------------------------------------------//
  update: async ({ resource = null, id = null, variables = {} }) => {
    const url =
      resource === 'users'
        ? `${apiUrl}/${resource}/admin/${id}`
        : `${apiUrl}/${resource}/${id}`
    const { data } = await httpClient.patch(url, variables)

    return {
      data,
    }
  },

  //? getList
  //?-------------------------------------------------------------------------//
  getList: async ({
    resource = null,
    pagination = { current: 1, pageSize: 20 },
    hasPagination = true,
    sort = [],
    filters = [],
  }) => {

    console.log('getList');
    const url = getUrl(apiUrl, resource)
    const { current = 1, pageSize = 20 } = pagination ?? {}
    const queryFilters = generateFilter(filters)

    const sorters = sort
      .map((v, i) => {
        return Object.keys(v).map((w, j) => {
          return `sorter[${i}][${w}]=${Object.values(v)[j]}`
        })
      })
      .flat()

    const queryStrings = [
      `pageSize=${pagination.pageSize}`,
      `current=${pagination.current}`,
      ...sorters,
    ]
    localStorage.setItem(`${resource}-list-uri`, queryStrings.join('&'))

    const query: {
      page?: number
      limit?: number
      sortBy?: string
      _order?: string
    } = hasPagination
      ? {
          page: current,
          limit: pageSize,
        }
      : {}
    const generatedSort = generateSort(sort)
    if (generatedSort) {
      const { _sort } = generatedSort
      query.sortBy = _sort.join(',')
    }


    // console.log(`${url}?${stringify(query)}&${stringify(queryFilters)}&filter.category=SCULPTURE`);


    const { data, headers } = await httpClient.get(
      `${url}?${stringify(query)}&${stringify(
        queryFilters
      )}`
    )
    const total = data.meta.totalItems // +headers['x-total-count']
    // console.log(`${url}?${stringify(query)}&${stringify(queryFilters)}`)

    console.log(`data, total => `, data, total);

    return {
      data,
      total,
    }
  },
})
